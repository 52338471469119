import * as auth from "../constants/auth";
import Cookies from "js-cookie";
import serverstatus from "../utils/status";
import {ShowMessageStatus} from "../utils/ui";
import api from "../utils/api";

export const SetToken = (data: any) => ({
  type: auth.SET_TOKEN,
  data,
});

export function UserLogin(payload: any, remember: boolean): any {
  return (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      api
        .post("/api/v1/user/login", JSON.stringify(payload))
        .then(function (response: any) {
          ShowMessageStatus(response.data, "Login successfully", null);
          if (response.data.status === serverstatus.SUCCESS) {
            dispatch(SetToken(response.data.access_token));
            Cookies.set("token", response.data.access_token, {
              expires: remember ? 365 : null,
            });
          }
          resolve(response.data);
        })
        .catch(function (error: any) {
          if (error.response) {
            ShowMessageStatus(error.response.data, null, null);
          } else {
            console.error("Error Message:", error.message);
          }
          reject(error);
        });
    });
  };
}

export function UserLogout(): any {
  return (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      api
        .get("/api/v1/user/logout")
        .then(function (response: any) {
          console.log("UserLogout", response.data);
          dispatch(SetToken(null));
          Cookies.remove("token");
          Cookies.remove("maika_token");
          Cookies.remove("oauth_state");
          window.location.reload();
          ShowMessageStatus(response.data, "Logout successfully", null);
          resolve(response.data);
        })
        .catch(function (error: any) {
          if (error.response) {
            dispatch(SetToken(null));
            Cookies.remove("token");
            Cookies.remove("maika_token");
            Cookies.remove("oauth_state");
            window.location.reload();
            ShowMessageStatus(error.response.data, null, null);
          } else {
            console.error("Error Message:", error.message);
          }
          reject(error);
        });
    });
  };
}
