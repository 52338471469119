export const SET_OAUTH_STATE = "SET_OAUTH_STATE";
export const SET_LINKING_STATUS = "SET_LINKING_STATUS";
export const SET_MAIKA_TOKEN = "SET_MAIKA_TOKEN";
export const SET_WS_ID = "SET_WS_ID";
export const SET_STEP_ACTIVE_TEST = "SET_STEP_ACTIVE_TEST";
export const SET_DEVICE_OBJECT = "SET_DEVICE_OBJECT";
export const SET_DEVICE_LIST = "SET_DEVICE_LIST";
export const SET_DEVICE_SUGGESTION_LIST = "SET_DEVICE_SUGGESTION_LIST";
export const SET_ROOM_SUGGESTION_LIST = "SET_ROOM_SUGGESTION_LIST";
export const SET_STRUCTURE_SUGGESTION_LIST = "SET_STRUCTURE_SUGGESTION_LIST";
