import Cookies from "js-cookie";
import {
  SetWsId,
  GetOauthState,
  SetOAuthState,
  SetStepActiveTest,
  SetLinkingStatus,
  GetDeviceList,
  SetDeviceList,
} from "./linking";
import serverstatus from "../utils/status";

export function InitWS(): any {
  return (dispatch: any, getState: any) => {
    let ws = new WebSocket(process.env.REACT_APP_EXPOSE_WS_BASEURL!);
    ws.onopen = () => {};
    ws.onmessage = async (ev: MessageEvent) => {
      const message = JSON.parse(ev.data);
      if (message.topic === "app_id") {
        dispatch(SetWsId(message.payload.app_id));
        const {MaikaToken} = getState().linking;
        const params = {
          app_id: message.payload.app_id,
        };
        console.log(`app_id: ${message.payload.app_id}`);
        dispatch(GetOauthState(params)).then(function (data: any) {
          if (data.status === serverstatus.SUCCESS) {
            console.log(`oauth_state: ${data.payload.oauth_state}`);
            Cookies.set("oauth_state", data.payload.oauth_state, {
              expires: 30,
            });
            dispatch(SetOAuthState(data.payload.oauth_state));
          }
        });
        ws.send(
          JSON.stringify({
            topic: "access_token",
            payload: {
              token: MaikaToken,
            },
          })
        );
      } else if (message.topic === "linking_status") {
        if (message.payload.status === true) {
          const {CurrentProjectInfo} = getState().actions;
          dispatch(await GetDeviceList({partner: CurrentProjectInfo.ProjectName})).then(
            function (data: any) {
              if (data.status === serverstatus.SUCCESS) {
                dispatch(SetLinkingStatus("linked"));
              }
            }
          );
        }
      } else if (message.topic === "unlink") {
        if (message.payload.status === serverstatus.SUCCESS) {
          dispatch(SetLinkingStatus("unlinked"));
          dispatch(SetDeviceList([]));
        }
      }
    };
    ws.onclose = (ev: CloseEvent) => {
      console.log("wsOnClose", ev);
    };
  };
}
