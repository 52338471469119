import Cookies from "js-cookie";
import * as linking from "../constants/linking";
import serverstatus from "../utils/status";
import api from "../utils/api";
import {ShowMessageStatus} from "../utils/ui";
import {InitWS} from "../actions/ws";

export const SetOAuthState = (data: any) => ({
  type: linking.SET_OAUTH_STATE,
  data,
});

export const SetLinkingStatus = (data: any) => ({
  type: linking.SET_LINKING_STATUS,
  data,
});

export const SetMaikaToken = (data: any) => ({
  type: linking.SET_MAIKA_TOKEN,
  data,
});

export const SetWsId = (data: any) => ({
  type: linking.SET_WS_ID,
  data,
});

export const SetStepActiveTest = (data: any) => ({
  type: linking.SET_STEP_ACTIVE_TEST,
  data,
});

export const SetDeviceObject = (data: any) => ({
  type: linking.SET_DEVICE_OBJECT,
  data,
});

export const SetDeviceList = (data: any) => ({
  type: linking.SET_DEVICE_LIST,
  data,
});

export const SetDeviceSuggestionList = (data: any) => ({
  type: linking.SET_DEVICE_SUGGESTION_LIST,
  data,
});

export const SetRoomSuggestionList = (data: any) => ({
  type: linking.SET_ROOM_SUGGESTION_LIST,
  data,
});

export const SetStructureSuggestionList = (data: any) => ({
  type: linking.SET_STRUCTURE_SUGGESTION_LIST,
  data,
});

export function GetLinkingStatus(): any {
  return (dispatch: any, getState: any) => {
    return new Promise(async (resolve, reject) => {
      const {MaikaToken} = getState().linking;
      const {CurrentProjectInfo} = getState().actions;
      console.log("CurrentProjectInfo", CurrentProjectInfo);
      const options = {
        headers: {Authorization: `Bearer ${MaikaToken}`},
        params: {partner: CurrentProjectInfo.ProjectName},
      };
      api
        .get(process.env.REACT_APP_EXPOSE_BASEURL! + "/api/v1.3/linking/status", options)
        .then(function (response: any) {
          if (response.data.status === serverstatus.SUCCESS) {
            if (response.data.payload.status === true) {
              const {CurrentProjectInfo} = getState().actions;
              dispatch(SetLinkingStatus("linked"));
              dispatch(GetDeviceList({partner: CurrentProjectInfo.ProjectName}));
              dispatch(SetStepActiveTest(response.data.payload.status));
            }
          }
          resolve(response.data);
        })
        .catch(function (error: any) {
          if (error.response) {
            ShowMessageStatus(error.response.data, null, null);
          } else {
            console.error("Error Message:", error.message);
          }
          reject(error);
        });
    });
  };
}

export function GetOauthState(params: any): any {
  return (dispatch: any, getState: any) => {
    return new Promise(async (resolve, reject) => {
      const {MaikaToken} = getState().linking;
      const options = {
        params: params,
        headers: {Authorization: `Bearer ${MaikaToken}`},
      };
      api
        .get(
          process.env.REACT_APP_EXPOSE_BASEURL! + "/api/v1.3/linking/oauth_state",
          options
        )
        .then(function (response: any) {
          resolve(response.data);
        })
        .catch(function (error: any) {
          if (error.response) {
            ShowMessageStatus(error.response.data, null, null);
          } else {
            console.error("Error Message:", error.message);
          }
          reject(error);
        });
    });
  };
}

export function LoginMaika(payload: any): any {
  return (dispatch: any, getState: any) => {
    let url: string =
      payload.email !== undefined
        ? process.env.REACT_APP_USER_SERVICE_URI_EMAIL!
        : process.env.REACT_APP_USER_SERVICE_URI_PHONE!;
    api
      .post(url, JSON.stringify(payload))
      .then(function (response: any) {
        const data = response.data.data;
        if (data !== undefined) {
          if (data.access_token !== undefined) {
            dispatch(SetMaikaToken(data.access_token));
            Cookies.set("maika_token", data.access_token, {
              expires: 30,
            });
            /* Initialize websocket client */
            dispatch(InitWS());

            const {WsId} = getState().linking;
            const params = {
              maika_token: data.access_token,
              id: WsId,
            };
            dispatch(GetOauthState(params)).then(function (data: any) {
              console.log("GetOauthState", data);
              if (data.status === serverstatus.SUCCESS) {
                Cookies.set("oauth_state", data.payload.oauth_state, {
                  expires: 30,
                });
                dispatch(SetOAuthState(data.payload.oauth_state));
              }
            });
          }
        }
      })
      .catch(function (error: any) {
        if (error.response) {
          ShowMessageStatus(error.response.data, null, null);
        } else {
          console.error("Error Message:", error.message);
        }
      });
  };
}

export function Unlink(): any {
  return (dispatch: any, getState: any) => {
    return new Promise(async (resolve, reject) => {
      const {MaikaToken} = getState().linking;
      const {CurrentProjectInfo} = getState().actions;
      const options = {
        headers: {Authorization: `Bearer ${MaikaToken}`},
        params: {partner: CurrentProjectInfo.ProjectName},
      };
      api
        .get(process.env.REACT_APP_EXPOSE_BASEURL! + "/api/v1.3/linking/unlink", options)
        .then(function (response: any) {
          if (response.data.status === serverstatus.SUCCESS) {
            dispatch(SetLinkingStatus("unlinked"));
            dispatch(SetDeviceList([]));
          }
          resolve(response.data);
        })
        .catch(function (error: any) {
          if (error.response) {
            ShowMessageStatus(error.response.data, null, null);
          } else {
            console.error("Error Message:", error.message);
          }
          reject(error);
        });
    });
  };
}

export function GetDeviceList(data: any): any {
  return (dispatch: any, getState: any) => {
    return new Promise(async (resolve, reject) => {
      const {MaikaToken} = getState().linking;
      const options = {
        headers: {Authorization: `Bearer ${MaikaToken}`},
      };
      let resData: any;
      await api
        .post(
          process.env.REACT_APP_EXPOSE_BASEURL! + "/api/v1.3/intent/sync",
          data,
          options
        )
        .then(function (response: any) {
          console.log(response.data);
          if (response.data.status === serverstatus.SUCCESS) {
            resData = response.data;
          }
        })
        .catch(function (error: any) {
          console.log(error.response);
          if (error.response) {
            ShowMessageStatus(error.response.data, null, null);
          } else {
            console.error("Error Message:", error.message);
          }
          reject(error);
        });

      if (resData === undefined) {
        return;
      }

      let identifierList: string[] = [];
      let DeviceObject: any = {};
      console.log("resData", resData);
      for (let i in resData.payload) {
        const id = resData.payload[i].n.split(":")[3];
        if (identifierList.includes(id)) {
          DeviceObject[`${id}`].u.push(resData.payload[i].u);
        } else {
          identifierList.push(id);
          DeviceObject[`${id}`] = {
            raw: resData.payload[i].n,
            type: resData.payload[i].ty,
            u: [resData.payload[i].u],
          };
        }
      }

      dispatch(SetDeviceObject(DeviceObject));

      let payload: any = {
        ids: [],
      };

      for (let key in DeviceObject) {
        payload.ids.push(parseInt(key));
      }

      await api
        .post(
          process.env.REACT_APP_EXPOSE_BASEURL! + "/api/v1.3/device/list",
          payload,
          options
        )
        .then(function (response: any) {
          if (response.data.status === serverstatus.SUCCESS) {
            resData = response.data;
          }
        })
        .catch(function (error: any) {
          if (error.response) {
            ShowMessageStatus(error.response.data, null, null);
          } else {
            console.error("Error Message:", error.message);
          }
          reject(error);
        });

      let _list: any[] = [];
      if (resData.payload.structures !== undefined) {
        for (const structure of resData.payload.structures) {
          for (const room of structure.rooms) {
            for (const device of room.devices) {
              _list.push({
                id: device.id,
                structure: structure.name,
                room: room.name,
                name: device.name,
                type: "",
              });
            }
          }
        }
      }
      if (resData.payload.availables !== undefined) {
        for (const available of resData.payload.availables) {
          _list.push({
            id: available.id,
            structure: "",
            room: "",
            name: available.name,
            type: "",
          });
        }
      }
      dispatch(SetDeviceList(_list));

      resolve({
        status: serverstatus.SUCCESS,
      });
    });
  };
}

export function UpdateDevice(params: any, payload: any): any {
  return (dispatch: any, getState: any) => {
    return new Promise(async (resolve, reject) => {
      const {MaikaToken} = getState().linking;
      const options = {
        params: params,
        headers: {Authorization: `Bearer ${MaikaToken}`},
      };
      api
        .put(
          process.env.REACT_APP_EXPOSE_BASEURL! + "/api/v1.3/device/update",
          payload,
          options
        )
        .then(function (response: any) {
          resolve(response.data);
        })
        .catch(function (error: any) {
          if (error.response) {
            ShowMessageStatus(error.response.data, null, null);
          } else {
            console.error("Error Message:", error.message);
          }
          reject(error);
        });
    });
  };
}
