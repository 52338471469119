import * as actions from "../constants/actions";
import serverstatus from "../utils/status";
import api from "../utils/api";
import {ShowMessageStatus} from "../utils/ui";
import {encodeRFC5987ValueChars} from "../utils/helper";

export const SetProjectList = (data: any) => ({
  type: actions.SET_PROJECT_LIST,
  data,
});

export const SetDeviceStatus = (data: any) => ({
  type: actions.SET_DEVICE_STATUS,
  data,
});

export const SetCurrentProjectInfo = (data: any) => ({
  type: actions.SET_CURRENT_PROJECT_INFO,
  data,
});

export const SetKeyText = (data: any) => ({
  type: actions.SET_KEY_TEXT,
  data,
});

export function GetProjectList(): any {
  return (dispatch: any) => {
    api
      .get("/api/v1/action/project_list")
      .then(function (response: any) {
        if (response.data.status === serverstatus.SUCCESS) {
          let _list: any[] = [];
          for (let i in response.data.payload) {
            _list.push({
              project: response.data.payload[i].name,
              owner: "",
              date: response.data.payload[i].updated_at,
            });
          }
          dispatch(SetProjectList(_list));
        }
      })
      .catch(function (error: any) {
        if (error.response) {
          ShowMessageStatus(error.response.data, null, null);
        } else {
          console.error("Error Message:", error.message);
        }
      });
  };
}

export function GetProjectInfo(params: any): any {
  return (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      const options = {
        params: params,
      };
      api
        .get("/api/v1/action/project_info", options)
        .then(async function (response: any) {
          if (response.data.status === serverstatus.SUCCESS) {
            let scopes: string[] = Array();
            scopes =
              response.data.payload.Scope === null
                ? []
                : response.data.payload.Scope === ""
                ? []
                : response.data.payload.Scope.split(" ");

            const _info = {
              ProjectName: params.project_name,
              DisplayName: response.data.payload.DisplayName,
              FulfillmentURL: response.data.payload.FulfillmentURL,
              IconURL: response.data.payload.IconURL,
              Schema: response.data.payload.Schema,
              AccountLinking: {
                ClientID: response.data.payload.ClientID,
                ClientSecret: response.data.payload.ClientSecret,
                AuthorizationURL: response.data.payload.AuthorizationURL,
                TokenURL: response.data.payload.TokenURL,
                Scope: scopes,
              },
            };
            console.log(_info);
            await dispatch(SetKeyText(response.data.payload.apiKey));
            await dispatch(SetCurrentProjectInfo(_info));
          }
          resolve(response.data);
        })
        .catch(function (error: any) {
          if (error.response) {
            ShowMessageStatus(error.response.data, null, null);
          } else {
            console.error("Error Message:", error.message);
          }
          reject(error);
        });
    });
  };
}

export function GetDeviceStatus(body: any): any {
  return (dispatch: any) => {
    api
      .post("/api/v1/action/device_status", JSON.stringify(body))
      .then(function (response: any) {
        if (response.data.status === serverstatus.SUCCESS) {
          dispatch(SetDeviceStatus(response.data.ListStatus));
        }
      })
      .catch(function (error: any) {
        if (error.response) {
          ShowMessageStatus(error.response.data, null, null);
        } else {
          console.error("Error Message:", error.message);
        }
      });
  };
}

export function CreateActions(body: any): any {
  return () => {
    return new Promise(async (resolve, reject) => {
      api
        .post("/api/v1/action", JSON.stringify(body))
        .then(function (response: any) {
          console.log("CreateActions", response.data);
          ShowMessageStatus(response.data, "Creating project successfully", {
            code: serverstatus.DB_DUPLICATE_KEY,
            msg: "Duplicate project name",
          });
          resolve(response.data);
        })
        .catch(function (error: any) {
          if (error.response) {
            ShowMessageStatus(error.response.data, null, null);
          } else {
            console.error("Error Message:", error.message);
          }
          reject(error);
        });
    });
  };
}

export function UpdateActions(params: any, body: any): any {
  return () => {
    return new Promise(async (resolve, reject) => {
      const options = {
        params: params,
      };
      api
        .put("/api/v1/action", JSON.stringify(body), options)
        .then(function (response: any) {
          ShowMessageStatus(response.data, "Update actions successfully", {
            code: serverstatus.HTTP_BODY_INVALID,
            msg: "Nothing project name",
          });
          resolve(response.data);
        })
        .catch(function (error: any) {
          if (error.response) {
            ShowMessageStatus(error.response.data, null, null);
          } else {
            console.error("Error Message:", error.message);
          }
          reject(error);
        });
    });
  };
}

export function DeleteActions(params: any): any {
  return (dispatch: any) => {
    const options = {
      params: params,
    };
    api
      .delete("/api/v1/action", options)
      .then(function (response: any) {
        ShowMessageStatus(response.data, "Delete actions successfully", {
          code: serverstatus.HTTP_QUERY_INVALID,
          msg: "Nothing project name",
        });
        if (response.data.status === serverstatus.SUCCESS) {
          dispatch(GetProjectList());
        }
      })
      .catch(function (error: any) {
        if (error.response) {
          ShowMessageStatus(error.response.data, null, null);
        } else {
          console.error("Error Message:", error.message);
        }
      });
  };
}

export function GenerateKey(params: any): any {
  return (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      const options = {
        params: params,
      };
      api
        .get("/api/v1/action/key", options)
        .then(function (response: any) {
          if (response.data.status === serverstatus.SUCCESS) {
            // dispatch(SetKeyText(response.data.payload.key));
          }
          resolve(response.data);
        })
        .catch(function (error: any) {
          if (error.response) {
            ShowMessageStatus(error.response.data, null, null);
          } else {
            console.error("Error Message:", error.message);
          }
          reject(error);
        });
    });
  };
}

export function UploadFiletoServer(fileName: string, fileUpload: any): any {
  return (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      const options = {
        headers: {
          "Content-Type": "multipart/form-data",
          "File-Name": fileName,
        },
      };
      const formData = new FormData();
      formData.append("file", fileUpload);
      api
        .post("/api/v1/action/upload/partner_icon", formData, options)
        .then(function (response: any) {
          console.error(response);
          if (response.data.status === serverstatus.SUCCESS) {
          }
          resolve(response.data);
        })
        .catch(function (error: any) {
          console.error(error);
          if (error.response) {
            ShowMessageStatus(error.response.data, null, null);
          } else {
            console.error("Error Message:", error.message);
          }
          reject(error);
        });
    });
  };
}
