import * as actions from "../constants/actions";

const INIT_STATE = {
  TableData: [],
  CurrentProjectInfo: {
    DisplayName: "",
    FulfillmentURL: "",
    IconURL: "",
    Schema: null,
    AccountLinking: {
      ClientID: "",
      ClientSecret: "",
      AuthorizationURL: "",
      TokenURL: "",
      Scope: [],
    },
  },
  DeviceStatus: {},
  KeyText: "",
};

export default (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case actions.SET_PROJECT_LIST:
      return Object.assign({}, state, {
        TableData: action.data,
      });
    case actions.SET_DEVICE_STATUS:
      return Object.assign({}, state, {
        DeviceStatus: action.data,
      });
    case actions.SET_CURRENT_PROJECT_INFO:
      return Object.assign({}, state, {
        CurrentProjectInfo: action.data,
      });
    case actions.SET_KEY_TEXT:
      return Object.assign({}, state, {
        KeyText: action.data,
      });

    default:
      return state;
  }
};
