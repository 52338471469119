import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {
  Menu,
  Input,
  Form,
  Button,
  Steps,
  Tabs,
  Card,
  Message,
  Table,
  Layout,
  AutoComplete,
  Dialog,
  Upload,
  Collapse,
  Tag,
  Radio,
} from "element-react";
import JSONPretty from "react-json-pretty";
import Cookies from "js-cookie";
import jsontheme from "../constants/jsonPrettyTheme";

import {InitWS} from "../actions/ws";

import {
  UpdateActions,
  GetProjectInfo,
  GetDeviceStatus,
  SetCurrentProjectInfo,
  SetDeviceStatus,
  GenerateKey,
  SetKeyText,
  UploadFiletoServer,
} from "../actions/actions";

import {
  LoginMaika,
  Unlink,
  SetLinkingStatus,
  GetLinkingStatus,
  GetDeviceList,
  UpdateDevice,
  SetDeviceList,
  SetDeviceObject,
} from "../actions/linking";

import qs from "qs";
import "./Project.scss";

import serverstatus from "../utils/status";

interface PropsType {
  UpdateActions: any;
  GetProjectInfo: any;
  GetDeviceList: any;
  GetDeviceStatus: any;
  SetCurrentProjectInfo: any;
  LoginMaika: any;
  SetDeviceStatus: any;
  InitWS: any;
  location: any;
  actions: any;
  auth: any;
  linking: any;
  Unlink: any;
  SetLinkingStatus: any;
  GetLinkingStatus: any;
  KeyText: string;
  GenerateKey: any;
  SetKeyText: any;
  UpdateDevice: any;
  SetDeviceList: any;
  SetDeviceObject: any;
  UploadFiletoServer: any;
}

interface StateType {
  Active: string;
  ProjectName: string;
  iFrameActiveTest: boolean;
  OAuthLink: string;
  login: any;
  DeviceListValue: string;
  DeviceListColumns: any;
  dialogArrangementVisible: boolean;
  dialogArrangementCurrentIndex: number | null;
  PreDeviceName: string;
  DeviceName: string;
  inputVisibleScope: boolean;
  inputValueScope: string;
  selectKey: number;
}

function validator(value: string) {
  const regex_email = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/g;
  const regex_phonenumber = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;
  const match_email = value.match(regex_email);
  const match_phonenumber = value.match(regex_phonenumber);
  return [match_email, match_phonenumber];
}

class Project extends React.Component<PropsType, StateType> {
  private saveTagInputScope = React.createRef<HTMLInputElement>();

  constructor(props: any) {
    super(props);
    this.state = {
      Active: "5",
      ProjectName: "",
      iFrameActiveTest: false,
      OAuthLink: "",
      login: {
        user: "",
        pass: "",
      },
      DeviceListValue: "",
      dialogArrangementVisible: false,
      dialogArrangementCurrentIndex: null,
      PreDeviceName: "",
      DeviceName: "",
      inputVisibleScope: false,
      inputValueScope: "",
      selectKey: 0,
      DeviceListColumns: [
        {
          label: "#",
          headerAlign: "left",
          width: 80,
          render: (row: number, column: number, index: number) => {
            return (
              <div style={{margin: "0px", padding: "0px", textAlign: "left"}}>
                {index}
              </div>
            );
          },
        },
        {
          label: "Structure",
          prop: "structure",
          headerAlign: "left",
        },
        {
          label: "Room",
          prop: "room",
          headerAlign: "left",
        },
        {
          label: "Name",
          prop: "name",
          headerAlign: "left",
        },
        {
          label: "Type",
          prop: "type",
          headerAlign: "left",
        },
        {
          label: "",
          fixed: "right",
          width: 70,
          render: (row: number, column: number, index: number) => {
            return (
              <span>
                <Layout.Row>
                  <Button
                    type="text"
                    onClick={this.handleSelectDeviceListTable.bind(this, index)}
                  >
                    Select
                  </Button>
                </Layout.Row>
              </span>
            );
          },
        },
      ],
    };
  }

  componentWillMount() {
    const ProjectName = qs.parse(this.props.location.search, {ignoreQueryPrefix: true})
      .name;
    this.setState({ProjectName: ProjectName});
    this.props.GetProjectInfo({project_name: ProjectName}).then((data: any) => {
      if (data.status === serverstatus.SUCCESS) {
        if (this.props.linking.LoginActive) {
          this.props.InitWS();
          this.props.GetLinkingStatus();
        }
      }
    });
  }

  onSelectMenu(value: any) {
    this.setState({Active: value});
  }

  onChangeInvocation(value: any) {
    this.props.actions.CurrentProjectInfo.DisplayName = value;
    this.props.SetCurrentProjectInfo(this.props.actions.CurrentProjectInfo);
  }

  handleSaveInvocation(e: any) {
    e.preventDefault();
    const params = {
      project_name: this.state.ProjectName,
    };
    const payload = {
      DisplayName: this.props.actions.CurrentProjectInfo.DisplayName,
    };
    this.props.UpdateActions(params, payload);
  }

  onChangeActions(value: any) {
    this.props.actions.CurrentProjectInfo.FulfillmentURL = value;
    this.props.SetCurrentProjectInfo(this.props.actions.CurrentProjectInfo);
  }

  handleSaveActions(e: any) {
    e.preventDefault();
    const params = {
      project_name: this.state.ProjectName,
    };
    const payload = {
      FulfillmentURL: this.props.actions.CurrentProjectInfo.FulfillmentURL,
    };
    this.props.UpdateActions(params, payload);
  }

  onChangeAccountLinking(key: any, value: any) {
    this.props.actions.CurrentProjectInfo.AccountLinking = Object.assign(
      this.props.actions.CurrentProjectInfo.AccountLinking,
      {[key]: value}
    );

    this.props.SetCurrentProjectInfo(this.props.actions.CurrentProjectInfo);
  }

  handleSaveAccountLinking(e: any) {
    e.preventDefault();

    let scopes:
      | string
      | null = this.props.actions.CurrentProjectInfo.AccountLinking.Scope.join(" ");
    if (scopes === "") {
      scopes = null;
    }

    const params = {
      project_name: this.state.ProjectName,
    };
    const data = {
      ClientID: this.props.actions.CurrentProjectInfo.AccountLinking.ClientID,
      ClientSecret: this.props.actions.CurrentProjectInfo.AccountLinking.ClientSecret,
      AuthorizationURL: this.props.actions.CurrentProjectInfo.AccountLinking
        .AuthorizationURL,
      TokenURL: this.props.actions.CurrentProjectInfo.AccountLinking.TokenURL,
      Scope: scopes,
    };
    this.props.UpdateActions(params, data);
  }

  download(file: string, text: string): void {
    //creating an invisible element
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", file);

    // Above code is equivalent to
    // <a href="path of file" download="file name">

    document.body.appendChild(element);

    //onClick property
    element.click();

    document.body.removeChild(element);
  }

  handleSubmitGenerateKey(e: any) {
    e.preventDefault();

    const params = {
      project_name: this.state.ProjectName,
      type: "api",
    };
    this.props.GenerateKey(params).then((data: any) => {
      if (data.status === serverstatus.SUCCESS) {
        console.log(data);
        this.props.SetKeyText(data.payload.api);
      }
    });
  }

  handleSubmitGenerateJson(e: any) {
    e.preventDefault();

    const params = {
      project_name: this.state.ProjectName,
      type: "serviceAccount",
    };
    this.props.GenerateKey(params).then((data: any) => {
      if (data.status === serverstatus.SUCCESS) {
        console.log(data);
        let jsonfile = {
          type: "service_account",
          project_id: this.state.ProjectName,
          private_key_id: "",
          private_key: data.payload.serviceAccount,
          client_email: `${this.state.ProjectName}@olli-ai.com`,
          client_id: "",
          auth_uri: "",
          token_uri: `${process.env.REACT_APP_HOMEGRAPH_URL!}/o/oauth2/token`,
          auth_provider_x509_cert_url: "",
          client_x509_cert_url: "",
        };
        this.download("maika_service_account.json", JSON.stringify(jsonfile, null, 2));
      }
    });
  }

  async StartTest(e: any) {
    e.preventDefault();
    if (this.props.actions.CurrentProjectInfo.DisplayName === null) {
      Message({
        message: "Fill Display name in invocation menu",
        type: "error",
        duration: 3000,
      });
      return;
    }

    let scopes:
      | string
      | null = this.props.actions.CurrentProjectInfo.AccountLinking.Scope.join(" ");

    let scopeStr: string = "";
    if (scopes !== "") {
      scopeStr = `&scope=${encodeURIComponent(scopes!)}`;
    }

    var OAuthLink =
      this.props.actions.CurrentProjectInfo.Schema === null
        ? `${
            this.props.actions.CurrentProjectInfo.AccountLinking.AuthorizationURL
          }?response_type=code&client_id=${
            this.props.actions.CurrentProjectInfo.AccountLinking.ClientID
          }&redirect_uri=${process.env.REACT_APP_ACTIONS_BASEURL}/api/v2/action/project/${
            this.state.ProjectName
          }${scopeStr}&state=${encodeURIComponent(
            this.props.linking.OAuthState
          )}&user_locale=en`
        : `${
            this.props.actions.CurrentProjectInfo.AccountLinking.AuthorizationURL
          }?response_type=code&client_id=${
            this.props.actions.CurrentProjectInfo.AccountLinking.ClientID
          }&redirect_uri=${process.env.REACT_APP_ACTIONS_BASEURL}/api/v2/action/project/${
            this.state.ProjectName
          }${scopeStr}&state=${encodeURIComponent(
            this.props.linking.OAuthState
          )}&user_locale=en&app_schema=${this.props.actions.CurrentProjectInfo.Schema}`;

    console.log("OAuthLink", OAuthLink);
    window.open(OAuthLink, "_blank");

    this.setState({
      OAuthLink: OAuthLink,
    });
    this.props.SetLinkingStatus("linking");
  }

  onChangeLogin(key: any, value: any): void {
    this.setState({
      login: Object.assign({}, this.state.login, {[key]: value}),
    });
  }

  handleSubmitLogin(e: any) {
    e.preventDefault();

    const match = validator(this.state.login.user);

    let data: any = {};
    if (match[0] !== null) {
      data = {
        email: this.state.login.user,
        password: this.state.login.pass,
      };
    } else if (match[1] !== null) {
      data = {
        phone_number: this.state.login.user,
        password: this.state.login.pass,
      };
    }
    if (match[0] !== null || match[1] !== null) {
      this.props.LoginMaika(data);
    }
  }

  handleUnlink(e: any) {
    e.preventDefault();
    this.props.Unlink();
  }

  async handleLogoutMaika(e: any) {
    e.preventDefault();
    Cookies.remove("maika_token");
    Cookies.remove("oauth_state");
    this.props.SetDeviceList([]);
    this.props.SetDeviceObject([]);
  }

  handleSelectDeviceListTable(index: number) {
    this.setState({dialogArrangementCurrentIndex: index});
    this.setState({dialogArrangementVisible: true});
    const DeviceName = this.props.linking.DeviceList[index].name;
    this.setState({DeviceName: DeviceName});
    this.setState({PreDeviceName: DeviceName});
  }

  onChangeArrangement(key: any, value: any) {
    switch (key) {
      case "name":
        this.setState({DeviceName: value});
        break;
    }
  }

  handleDialogArrangementOK(e: any) {
    e.preventDefault();
    this.setState({dialogArrangementVisible: false});

    if (this.state.DeviceName !== this.state.PreDeviceName) {
      const device = this.props.linking.DeviceList[
        this.state.dialogArrangementCurrentIndex!
      ];
      const params = {
        id: device.id,
      };
      const payload = {
        name: this.state.DeviceName,
      };
      this.props.UpdateDevice(params, payload).then((data: any) => {
        if (data.status === serverstatus.SUCCESS) {
          this.props.GetDeviceList({
            partner: this.state.ProjectName,
          });
        }
      });
    }
  }

  handleUploadFile = (e: any) => {
    const files = e.target.files;
    this.props
      .UploadFiletoServer(this.state.ProjectName, files[0])
      .then((data: any) => {
        this.props.actions.CurrentProjectInfo = Object.assign(
          this.props.actions.CurrentProjectInfo,
          {IconURL: data.payload.icon_url}
        );

        console.log(this.props.actions.CurrentProjectInfo);
        this.props.SetCurrentProjectInfo(this.props.actions.CurrentProjectInfo);
      })
      .catch(() => {});
  };

  handleUploadPartnerIcon(e: any) {
    e.preventDefault();
    const uploadFileElement: HTMLElement = document.getElementById("uploadFile")!;
    uploadFileElement.click();
  }

  onKeyUpScope(e: any) {
    e.preventDefault();
    if (e.key === "Enter") {
      this.handleInputConfirmScope();
    }
  }

  onChangeScope(e: any) {
    e.preventDefault();
    this.setState({inputValueScope: e.target.value});
  }

  handleCloseScope(index: number) {
    this.props.actions.CurrentProjectInfo = Object.assign(
      this.props.actions.CurrentProjectInfo,
      {Scope: this.props.actions.CurrentProjectInfo.AccountLinking.Scope.splice(index, 1)}
    );
    this.props.SetCurrentProjectInfo(this.props.actions.CurrentProjectInfo);
  }

  showInputScope() {
    this.setState({inputVisibleScope: true}, () => {
      this.saveTagInputScope.current!.focus();
    });
  }

  handleonBlurScope() {
    this.setState({inputVisibleScope: false});
    this.setState({inputValueScope: ""});
  }

  handleInputConfirmScope() {
    let inputValue = this.state.inputValueScope;
    if (inputValue) {
      inputValue = inputValue.trim();
      inputValue = inputValue.replace(/  +/g, " ");
      let inputValues: string[] = Array();
      inputValues = inputValue.split(" ");
      for (let scope of inputValues) {
        this.props.actions.CurrentProjectInfo.AccountLinking.Scope.push(scope);
      }

      this.props.actions.CurrentProjectInfo = Object.assign(
        this.props.actions.CurrentProjectInfo,
        {
          Scope: this.props.actions.CurrentProjectInfo.AccountLinking.Scope,
        }
      );
      this.props.SetCurrentProjectInfo(this.props.actions.CurrentProjectInfo);
    }

    this.setState({inputVisibleScope: false});
    this.setState({inputValueScope: ""});
  }

  onChangeKey(value: number): void {
    this.setState({selectKey: value});
  }

  render() {
    return (
      <div className="project">
        <div className="nav-bar">
          <Menu
            defaultActive={this.state.Active}
            className="project-menu"
            onSelect={this.onSelectMenu.bind(this)}
          >
            <Menu.Item index="1">
              <i className="fas fa-microphone"></i> Invocation
            </Menu.Item>
            <Menu.Item index="2">
              <i className="far fa-comment-alt"></i> Actions
            </Menu.Item>
            <Menu.Item index="3">
              <i className="far fa-user-circle"></i> Account linking
            </Menu.Item>
            <Menu.Item index="4">
              <i className="fas fa-key"></i> Key
            </Menu.Item>
            <Menu.Item index="5">
              <i className="fas fa-running"></i> Test
            </Menu.Item>
            <Menu.Item index="6">
              <i className="fas fa-cube"></i> Deploy
            </Menu.Item>
          </Menu>
        </div>
        <div className="project-main">
          {this.state.Active === "1" ? (
            <div className="main-container invocation">
              <Form labelPosition="top" labelWidth="100">
                <Form.Item className="heading">Invocation</Form.Item>
                <Form.Item>
                  <div className="sub-title">Choose a name for your Action.</div>
                  <div className="description">
                    Users say or type this name to begin interacting with it.
                  </div>
                </Form.Item>
                <Form.Item label="Display name">
                  <Input
                    value={this.props.actions.CurrentProjectInfo.DisplayName}
                    placeholder="Display name"
                    onChange={this.onChangeInvocation.bind(this)}
                  ></Input>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    nativeType="submit"
                    onClick={this.handleSaveInvocation.bind(this)}
                  >
                    Save
                  </Button>
                </Form.Item>
              </Form>
            </div>
          ) : this.state.Active === "2" ? (
            <div className="main-container actions">
              <Form labelPosition="top" labelWidth="100">
                <Form.Item className="heading">Webhook</Form.Item>
                <Form.Item className="description">
                  <div className="description">
                    Enter the webhook used to process your smart home intents.
                  </div>
                </Form.Item>
                <Form.Item label="Fulfillment URL">
                  <Input
                    value={this.props.actions.CurrentProjectInfo.FulfillmentURL}
                    placeholder="E.g https://example.com/smarthome"
                    onChange={this.onChangeActions.bind(this)}
                  ></Input>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    nativeType="submit"
                    onClick={this.handleSaveActions.bind(this)}
                  >
                    Save
                  </Button>
                </Form.Item>
              </Form>
            </div>
          ) : this.state.Active === "3" ? (
            <div className="main-container account-link">
              <Form
                model={this.props.actions.CurrentProjectInfo.AccountLinking}
                labelPosition="top"
                labelWidth="100"
              >
                <Form.Item className="heading">OAuth Client Information</Form.Item>
                <Form.Item className="description">
                  <div className="description">
                    Enter the OAuth client information used to process your smart home
                    intents.
                  </div>
                </Form.Item>
                <Form.Item label="Client ID issued by your Actions to Maika">
                  <Input
                    value={this.props.actions.CurrentProjectInfo.AccountLinking.ClientID}
                    placeholder="xxxxxxxx"
                    onChange={this.onChangeAccountLinking.bind(this, "ClientID")}
                  ></Input>
                </Form.Item>
                <Form.Item label="Client secret">
                  <Input
                    value={
                      this.props.actions.CurrentProjectInfo.AccountLinking.ClientSecret
                    }
                    placeholder="xxxxxxxx"
                    onChange={this.onChangeAccountLinking.bind(this, "ClientSecret")}
                  ></Input>
                </Form.Item>
                <Form.Item label="Authorization URL">
                  <Input
                    value={
                      this.props.actions.CurrentProjectInfo.AccountLinking
                        .AuthorizationURL
                    }
                    placeholder="https://example.com/auth"
                    onChange={this.onChangeAccountLinking.bind(this, "AuthorizationURL")}
                  ></Input>
                </Form.Item>
                <Form.Item label="Token URL">
                  <Input
                    value={this.props.actions.CurrentProjectInfo.AccountLinking.TokenURL}
                    placeholder="https://example.com/token"
                    onChange={this.onChangeAccountLinking.bind(this, "TokenURL")}
                  ></Input>
                </Form.Item>
                <Form.Item label="Scopes (optional)">
                  {this.props.actions.CurrentProjectInfo.AccountLinking.Scope.map(
                    (tag: string, index: number) => {
                      return (
                        <Tag
                          key={Math.random()}
                          closable={true}
                          closeTransition={false}
                          onClose={this.handleCloseScope.bind(this, index)}
                          type="primary"
                          style={{margin: "0 10px 0 0"}}
                        >
                          {tag}
                        </Tag>
                      );
                    }
                  )}

                  {this.state.inputVisibleScope ? (
                    <input
                      className="input-new-tag"
                      value={this.state.inputValueScope}
                      ref={this.saveTagInputScope}
                      onChange={this.onChangeScope.bind(this)}
                      onKeyUp={this.onKeyUpScope.bind(this)}
                      onBlur={this.handleonBlurScope.bind(this)}
                      onKeyPress={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                    />
                  ) : (
                    <Button
                      className="button-new-tag"
                      size="small"
                      onClick={this.showInputScope.bind(this)}
                    >
                      + New scope
                    </Button>
                  )}
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    nativeType="submit"
                    onClick={this.handleSaveAccountLinking.bind(this)}
                  >
                    Save
                  </Button>
                </Form.Item>
              </Form>
            </div>
          ) : this.state.Active === "4" ? (
            <div className="main-container key-section">
              <div>
                <Radio
                  value={0}
                  checked={this.state.selectKey === 0}
                  onChange={this.onChangeKey.bind(this)}
                >
                  API Key
                </Radio>
                <Radio
                  value={1}
                  checked={this.state.selectKey === 1}
                  onChange={this.onChangeKey.bind(this)}
                >
                  Service Account Key
                </Radio>
              </div>
              <Form className="keytext-form">
                {this.state.selectKey === 0 ? (
                  <>
                    <Form.Item className="heading">API Key</Form.Item>
                    <Form.Item>
                      <Input
                        readOnly={true}
                        value={this.props.actions.KeyText}
                        placeholder="Key"
                      ></Input>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type="primary"
                        nativeType="submit"
                        onClick={this.handleSubmitGenerateKey.bind(this)}
                      >
                        Generate Key
                      </Button>
                    </Form.Item>
                  </>
                ) : this.state.selectKey === 1 ? (
                  <>
                    <Form.Item className="heading">Service Account Key</Form.Item>
                    <Form.Item>
                      <Button
                        type="primary"
                        nativeType="submit"
                        onClick={this.handleSubmitGenerateJson.bind(this)}
                      >
                        Generate Json File
                      </Button>
                    </Form.Item>
                  </>
                ) : null}
              </Form>
            </div>
          ) : this.state.Active === "5" ? (
            <div className="test-block" style={{width: "100%"}}>
              <div className="status">
                <div className="statusleft">
                  {this.props.linking.LinkingStatus === "linked" ||
                  this.props.linking.LinkingStatus === "linking" ? (
                    <Button
                      plain={true}
                      type="info"
                      style={{margin: "10px"}}
                      onClick={this.handleUnlink.bind(this)}
                    >
                      Unlink
                    </Button>
                  ) : this.props.linking.LinkingStatus === "unlinked" ? (
                    <Button
                      type="info"
                      style={{margin: "10px"}}
                      onClick={this.StartTest.bind(this)}
                    >
                      Start
                    </Button>
                  ) : null}

                  {this.props.linking.LinkingStatus === "linking" ? (
                    <div style={{margin: "auto"}}>
                      <i className="el-icon-loading"></i>
                    </div>
                  ) : this.props.linking.LinkingStatus === "linked" ? (
                    <div style={{margin: "auto"}}>
                      <i className="el-icon-circle-check"></i>
                    </div>
                  ) : null}
                </div>
                <div className="statusright">
                  <div style={{margin: "auto"}}></div>
                </div>
              </div>
              <div className="table-data">
                <Table
                  style={{width: "100%", textAlign: "left"}}
                  columns={this.state.DeviceListColumns}
                  data={this.props.linking.DeviceList}
                />
                <Dialog
                  title="Rename"
                  size="small"
                  visible={this.state.dialogArrangementVisible}
                  onCancel={() => this.setState({dialogArrangementVisible: false})}
                  lockScroll={false}
                >
                  <Dialog.Body>
                    <Input
                      value={this.state.DeviceName}
                      placeholder="New name"
                      onChange={this.onChangeArrangement.bind(this, "name")}
                      prepend={this.state.PreDeviceName}
                    />
                  </Dialog.Body>
                  <Dialog.Footer className="dialog-footer">
                    <Button
                      onClick={() => this.setState({dialogArrangementVisible: false})}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      onClick={this.handleDialogArrangementOK.bind(this)}
                    >
                      OK
                    </Button>
                  </Dialog.Footer>
                </Dialog>
              </div>
            </div>
          ) : this.state.Active === "6" ? (
            <div className="deploy">
              <div>Upload Icon</div>
              <input
                type="file"
                id="uploadFile"
                accept="image/jpg, image/png, image/jpeg"
                onChange={this.handleUploadFile}
              />
              <div
                style={{marginTop: "10px"}}
                onClick={this.handleUploadPartnerIcon.bind(this)}
              >
                <Card className="partner-icon" bodyStyle={{padding: 0}}>
                  {this.props.actions.CurrentProjectInfo.IconURL !== null ? (
                    <img
                      src={this.props.actions.CurrentProjectInfo.IconURL}
                      width="100px"
                      height="100px"
                    />
                  ) : (
                    <div>
                      <i className="fas fa-file-image fa-3x"></i>
                    </div>
                  )}
                </Card>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    actions: state.actions,
    linking: state.linking,
    auth: state.auth,
  };
}

const mapDispatchToProps = {
  UpdateActions: UpdateActions,
  GetProjectInfo: GetProjectInfo,
  GetDeviceList: GetDeviceList,
  GetDeviceStatus: GetDeviceStatus,
  SetCurrentProjectInfo: SetCurrentProjectInfo,
  LoginMaika: LoginMaika,
  SetDeviceStatus: SetDeviceStatus,
  InitWS: InitWS,
  Unlink: Unlink,
  SetLinkingStatus: SetLinkingStatus,
  GetLinkingStatus: GetLinkingStatus,
  GenerateKey: GenerateKey,
  SetKeyText: SetKeyText,
  UpdateDevice: UpdateDevice,
  SetDeviceList: SetDeviceList,
  SetDeviceObject: SetDeviceObject,
  UploadFiletoServer: UploadFiletoServer,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Project));
