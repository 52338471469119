const status = {
  // Common status
  SUCCESS: 0,
  ERROR: 1,
  UNKNOWN: 1001,
  UNEXPECTED_RESPONSE: 1002,

  // HTTP status
  HTTP_PROJECT_NAME_INVALID: 2001,
  HTTP_BODY_INVALID: 2002,
  HTTP_QUERY_INVALID: 2003,

  // Token status
  TOKEN_EXPIRED: 3001,
  TOKEN_INVALID: 3002,
  TOKEN_REVOKED: 3003,
  TOKEN_INTERNAL_EXPIRED: 3004,
  TOKEN_DECODE_ERROR: 3005,

  // DB status
  DB_DUPLICATE_KEY: 4001,

  // DB Connector service status
  DB_USER_NOT_EXIST: 5001,
  DB_USER_ALREADY_EXISTS: 5002,
  DB_PROJECT_NOT_EXIST: 5003,
  DB_PROJECT_ALREADY_EXISTS: 5004,
  DB_PARTNER_USER_NOT_EXIST: 5005,
  DB_PARTNER_USER_ALREADY_EXISTS: 5006,
  DB_DEVICE_NOT_EXIST: 5007,
  DB_DEVICE_ALREADY_EXISTS: 5008,

  // Actions service status
  // Auth status
  WRONG_LOGIN_INFORMATION: 6001,
  WRONG_REGISTER_INFORMATION: 6002,
  WRONG_ACTION_STATE: 6003,
  WRONG_ACTION_PROJECT: 6004,
};

export default status;
