import Cookies from "js-cookie";
import * as linking from "../constants/linking";

const INIT_STATE = {
  LinkingStatus: "unlinked",
  LoginActive: Cookies.get("maika_token") !== undefined ? true : false,
  MaikaToken: Cookies.get("maika_token"),
  OAuthState: Cookies.get("oauth_state"),
  WsId: "",
  StepActiveTest: 0,
  DeviceObject: [],
  DeviceList: [],
  DeviceSuggestionList: [],
  RoomSuggestionList: [],
  StructureSuggestionList: [],
};

export default (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case linking.SET_OAUTH_STATE:
      return Object.assign({}, state, {
        OAuthState: action.data,
      });
    case linking.SET_LINKING_STATUS:
      return Object.assign({}, state, {
        LinkingStatus: action.data,
      });
    case linking.SET_MAIKA_TOKEN:
      return Object.assign({}, state, {
        MaikaToken: action.data,
      });
    case linking.SET_WS_ID:
      return Object.assign({}, state, {
        WsId: action.data,
      });
    case linking.SET_STEP_ACTIVE_TEST:
      return Object.assign({}, state, {
        StepActiveTest: action.data,
      });
    case linking.SET_DEVICE_OBJECT:
      return Object.assign({}, state, {
        DeviceObject: action.data,
      });
    case linking.SET_DEVICE_LIST:
      return Object.assign({}, state, {
        DeviceList: action.data,
      });
    case linking.SET_DEVICE_SUGGESTION_LIST:
      return Object.assign({}, state, {
        DeviceSuggestionList: action.data,
      });
    case linking.SET_ROOM_SUGGESTION_LIST:
      return Object.assign({}, state, {
        RoomSuggestionList: action.data,
      });
    case linking.SET_STRUCTURE_SUGGESTION_LIST:
      return Object.assign({}, state, {
        StructureSuggestionList: action.data,
      });
    default:
      return state;
  }
};
