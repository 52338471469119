import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Button, Table, Dialog, Input, Layout} from "element-react";
import {GetProjectList, CreateActions, DeleteActions} from "../actions/actions";
import serverstatus from "../utils/status";
import "./Home.scss";

interface PropsType {
  GetProjectList: any;
  CreateActions: any;
  DeleteActions: any;
  actions: any;
  history: any;
}

interface StateType {
  dialogVisible: boolean;
  ProjectName: string;
  actionListColumns: any;
}

class Home extends React.Component<PropsType, StateType> {
  static defaultProps = {};

  constructor(props: any) {
    super(props);

    this.state = {
      dialogVisible: false,
      ProjectName: "",
      actionListColumns: [
        {
          label: "Project",
          prop: "project",
          width: 180,
          headerAlign: "center",
        },
        {
          label: "Owner",
          prop: "owner",
          width: 180,
          headerAlign: "center",
        },
        {
          label: "Last modified date",
          prop: "date",
          headerAlign: "center",
        },
        {
          label: "Operations",
          fixed: "right",
          width: 150,
          render: (row: number, column: number, index: number) => {
            return (
              <span>
                <Layout.Row>
                  <Button type="text" onClick={this.handleSelectTable.bind(this, index)}>
                    Select
                  </Button>
                  <Button
                    style={{margin: "0px 0px 0px 20px"}}
                    type="text"
                    onClick={this.handleDeleteTable.bind(this, index)}
                  >
                    Delete
                  </Button>
                </Layout.Row>
              </span>
            );
          },
        },
      ],
    };
  }

  componentWillMount() {
    this.props.GetProjectList();
  }

  handleNewProject(e: any) {
    e.preventDefault();
    this.setState({dialogVisible: true});
  }

  handleCreate(e: any) {
    e.preventDefault();
    const data = {
      name: this.state.ProjectName,
    };
    this.props.CreateActions(data).then((data: any) => {
      if (data.status === serverstatus.SUCCESS) {
        this.props.history.push(`/h/project?name=${this.state.ProjectName}`);
      }
    });
    this.setState({dialogVisible: false});
  }

  onChange(key: any, value: any) {
    this.setState({ProjectName: value});
  }

  handleSelectTable(index: number) {
    this.props.history.push(
      `/h/project?name=${this.props.actions.TableData[index].project}`
    );
  }

  handleDeleteTable(index: number) {
    this.props.DeleteActions({project_name: this.props.actions.TableData[index].project});
  }

  render() {
    return (
      <div className="mainhome">
        <Dialog
          title="New Project"
          size="tiny"
          visible={this.state.dialogVisible}
          onCancel={() => this.setState({dialogVisible: false})}
          lockScroll={false}
        >
          <Dialog.Body>
            <Input
              value={this.state.ProjectName}
              placeholder="Project name"
              onChange={this.onChange.bind(this, "name")}
            ></Input>
          </Dialog.Body>
          <Dialog.Footer className="dialog-footer">
            <Button onClick={() => this.setState({dialogVisible: false})}>Cancel</Button>
            <Button type="primary" onClick={this.handleCreate.bind(this)}>
              Create
            </Button>
          </Dialog.Footer>
        </Dialog>
        <div style={{width: "100%"}}>
          <Button
            style={{margin: "10px 100% 10px 10px"}}
            type="primary"
            onClick={this.handleNewProject.bind(this)}
          >
            New Project
          </Button>
        </div>

        <Table
          style={{width: "100%", textAlign: "center"}}
          columns={this.state.actionListColumns}
          data={this.props.actions.TableData}
        />
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    actions: state.actions,
  };
}

const mapDispatchToProps = {
  GetProjectList: GetProjectList,
  CreateActions: CreateActions,
  DeleteActions: DeleteActions,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
