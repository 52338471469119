import React from "react";
import {Route, Switch, Redirect} from "react-router";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Dropdown} from "element-react";
import {UserLogout} from "../actions/auth";
import MainHome from "../components/Home";
import Project from "../components/Project";
import "./Home.scss";

interface PropsType {
  UserLogout: any;
  handleUserDropdown: any;
  history: any;
}

class Home extends React.Component<PropsType> {
  handleUserDropdown(command: string) {
    console.log("command", command);
    switch (command) {
      case "profile":
        this.props.history.push("/profile");
        break;
      case "settings":
        break;
      case "logout":
        this.props.UserLogout();
        break;
    }
  }

  handleHomeClick(e: any) {
    e.preventDefault();
    this.props.history.push("/");
  }

  render() {
    return (
      <div className="home">
        <div className="navigation">
          <div className="navitop">
            <div className="iconblock">
              <img
                src={require("../assets/img/olli-logo.svg")}
                width="40"
                height="40"
                onClick={this.handleHomeClick.bind(this)}
              />
            </div>
          </div>
          <div className="navibot">
            <div className="iconblock">
              <Dropdown
                onCommand={this.handleUserDropdown.bind(this)}
                menu={
                  <Dropdown.Menu>
                    <Dropdown.Item command="profile">
                      <i className="fas fa-user fa-lg"></i> User Profile
                    </Dropdown.Item>
                    <Dropdown.Item command="settings">
                      <i className="fas fa-cogs fa-lg"></i> Settings
                    </Dropdown.Item>
                    <Dropdown.Item command="logout">
                      <i className="fas fa-sign-out-alt fa-lg"></i> Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                }
              >
                <div>
                  <i
                    className="fas fa-user-circle fa-3x"
                    style={{
                      color: "white",
                      fontSize: "38px",
                    }}
                  ></i>
                </div>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className="main">
          <Switch>
            <Route path="/h/home" component={MainHome}></Route>
            <Route path="/h/project" component={Project}></Route>
            <Redirect to="/h/home" />
          </Switch>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  return {};
}

const mapDispatchToProps = {
  UserLogout: UserLogout,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
