import React from "react";
import {connect} from "react-redux";
import {withRouter, Link} from "react-router-dom";
import {Loading, Card, Form, Input, Button} from "element-react";
import {UserLogin} from "../actions/auth";
import {LoginMaika} from "../actions/linking";
import serverstatus from "../utils/status";
import "./Login.scss";

interface PropsType {
  login_props: string;
  UserLogin: any;
  LoginMaika: any;
  history: any;
  auth: any;
}

interface StateType {
  form: any;
  rules: object;
  loading: boolean;
}

function validator(value: string) {
  const regex_email = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/g;
  const regex_phonenumber = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;
  const match_email = value.match(regex_email);
  const match_phonenumber = value.match(regex_phonenumber);
  return [match_email, match_phonenumber];
}

class Login extends React.Component<PropsType, StateType> {
  static defaultProps = {};

  constructor(props: any) {
    super(props);

    this.state = {
      form: {
        user: "",
        password: "",
      },

      rules: {
        user: [
          {
            required: true,
            message: "Please input email or phone number",
            trigger: "blur",
          },
          {
            validator: (_: any, value: string, callback: any) => {
              const match = validator(value);

              if (match[0] === null && match[1] === null) {
                callback(new Error("Please input email or phone number"));
              }

              if (match[0] !== null) {
                callback();
              } else if (match[1] !== null) {
                if (value.length < 11) {
                  callback(new Error("Phone number too short"));
                } else {
                  callback();
                }
              }
            },
            trigger: ["blur", "change"],
          },
        ],
        password: [
          {required: true, message: "Password is required", trigger: "blur"},
          {
            min: 5,
            message: "Password length should be at least 5 characters",
            trigger: "blur",
          },
        ],
      },

      loading: false,
    };
  }

  onChange(key: any, value: any) {
    this.setState({
      form: Object.assign({}, this.state.form, {[key]: value}),
    });
  }

  handleSubmit(e: any) {
    e.preventDefault();

    (this as any).refs.form.validate((valid: boolean) => {
      if (valid) {
        this.setState({
          loading: true,
        });

        const match = validator(this.state.form.user);

        let data: any = {};
        if (match[0] !== null) {
          data = {
            email: this.state.form.user,
            password: this.state.form.password,
          };
        } else if (match[1] !== null) {
          data = {
            phone_number: this.state.form.user,
            password: this.state.form.password,
          };
        }

        this.props.UserLogin(data, true).then(async (dataRes: any) => {
          if (dataRes.status === serverstatus.SUCCESS) {
            await this.props.LoginMaika(data);
            // window.location.reload();
          }
          this.setState({
            loading: false,
          });
        });
      } else {
        console.log("error submit!!");
        return false;
      }
    });
  }

  render() {
    return (
      <div className="login">
        <Loading loading={this.state.loading}>
          <Card>
            <h2>Login</h2>
            <Form
              className="login-form"
              model={this.state.form}
              rules={this.state.rules}
              ref="form"
            >
              <Form.Item prop="user">
                <Input
                  value={this.state.form.user}
                  placeholder="Email or Phone number (+84)"
                  onChange={this.onChange.bind(this, "user")}
                ></Input>
              </Form.Item>
              <Form.Item prop="password">
                <Input
                  value={this.state.form.password}
                  placeholder="Password"
                  type="password"
                  onChange={this.onChange.bind(this, "password")}
                ></Input>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  className="login-button"
                  native-type="submit"
                  onClick={this.handleSubmit.bind(this)}
                >
                  Login
                </Button>
              </Form.Item>
            </Form>
            <Link to="/signup">Register a new Account</Link>
          </Card>
        </Loading>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    auth: state.auth,
  };
}

const mapDispatchToProps = {
  UserLogin: UserLogin,
  LoginMaika: LoginMaika,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
